import React from 'react'
import { graphql } from 'gatsby'
import { H2 } from '@blueprintjs/core'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import '../style/influences.scss'

export interface Book {
    id: string
    title: string
    author: string
    link?: string
}

export interface Podcast {
    id: string
    title: string
}

export const query = graphql`
    query BooksQuery {
        allContentfulBook {
            edges {
                node {
                    id
                    title
                    author
                    link
                }
            }
        }
        allContentfulPodcast {
            edges {
                node {
                    id
                    title
                }
            }
        }
    }
`

const BookView = ({ title, author, link }: Book) =>
    link ? (
        <li>
            <a target='_blank' href={link}>
                {title + ' - ' + author}
            </a>
        </li>
    ) : (
        <li>{title + ' - ' + author}</li>
    )

const PodcastView = ({ title }: Podcast) => <li>{title}</li>

const Influences = ({ data }: any) => {
    const books: Book[] = data.allContentfulBook.edges.map(
        ({ node }: any) => node
    )
    const podcasts: Podcast[] = data.allContentfulPodcast.edges.map(
        ({ node }: any) => node
    )

    return (
        <Layout page='/influences'>
            <SEO title='Influences' />
            <div className='Influence'>
                <H2>Books</H2>
                <ul>
                    {books.map(book => (
                        <BookView key={book.id} {...book} />
                    ))}
                </ul>
                <H2>Podcasts</H2>
                <ul>
                    {podcasts.map(podcast => (
                        <PodcastView key={podcast.id} {...podcast} />
                    ))}
                </ul>
            </div>
        </Layout>
    )
}

export default Influences
